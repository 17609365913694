import styled, { css, keyframes } from "styled-components/macro";
import { theme } from "../../styles/theme";
export const DivContainer = styled.div<{
  fontSize?: string;
  isModal?: boolean;
  isLoading?: boolean;
  whiteBackground?: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: ${(props) => props.fontSize || "1em"};
  color: ${theme.colors.borderSecondary};
  & > span {
    text-align: center;
    max-width: 90%;
  }
  &::before {
    content: "";
    border: 0.1em solid rgba(211, 211, 211, 0.4);
    border-top-color: inherit;
    border-bottom-color: inherit;
    border-radius: 50%;
    height: 0.7em;
    width: 0.7em;
    ${(props) =>
      props.isLoading &&
      css`
        animation: ${loaderAnime} 1.1s infinite 0.1s ease-in-out;
      `}
  }
  ${(props) =>
    props.isModal &&
    css`
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.6);
      z-index: 20;
    `};
  ${(props) =>
    props.whiteBackground &&
    css`
      background-color: rgba(255, 255, 255, 0.43);
    `};
  @media (max-width: 570px) {
    & span {
      display: none;
    }
  }
`;

const loaderAnime = keyframes`
    to {
        transform: rotate(360deg);
    }
`;
