import { State, To } from "history";
import { generatePath, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useCallback } from "react";

export const debounce = (func: (...arg: any) => void, timeout: number) => {
  let timer: ReturnType<typeof setTimeout>;

  const fn = (...arg: any) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(null, arg);
    }, timeout);
  };

  fn.cancel = () => {
    clearTimeout(timer);
  };

  return fn;
};

function isStringPath(path: To): path is string {
  return typeof path === "string";
}
function containsLngParameter(path: string) {
  return /^\/:lng/.test(path);
}

export const useHospiNavigate = () => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();

  return useCallback(
    (
      path: To,
      options?: {
        replace?: boolean;
        state?: State;
      }
    ) => {
      let finalPath: To | undefined;
      if (isStringPath(path)) {
        if (containsLngParameter(path)) {
          finalPath = generatePath(path, {
            lng: i18n.language
          });
        } else {
          finalPath = path;
        }
      } else {
        if (path.pathname && containsLngParameter(path.pathname)) {
          finalPath = {
            pathname: generatePath(path.pathname, {
              lng: i18n.language
            }),
            hash: path.hash,
            search: path.search
          };
        } else {
          finalPath = path;
        }
      }

      navigate(finalPath, options);
    },
    [i18n.language, navigate]
  );
};

export const hexToRgba = (hex: string, alpha = 1) => {
  const [r, g, b] = [
    parseInt(hex.slice(1, 3), 16),
    parseInt(hex.slice(3, 5), 16),
    parseInt(hex.slice(5, 7), 16)
  ];
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

export const capitalizeFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const isPositiveInteger = (value: string): boolean => {
  if (!/^[0-9]*$/.test(value)) return false;
  return Number(value) > 0;
};
