import { ServiceApi } from "utils/utilAmplify";
import {
  IPerson,
  IResponseShort,
  IUploadAvatarResponse,
  IUserShort,
  TReview,
  TUserShort
} from "interfaces/interfaces";
import { ServiceName } from "../config/amplify";
import {
  IStepValueAboutMyself,
  IStepValueExpectations,
  IStepValueMatchExpectations,
  IStepValuePersonalInfo,
  IStepValueSearchPreferences,
  IWizardStepMetaData,
  IWizardStudentState,
  TStepPayload,
  IWizardHostState,
  IStepValueReferral,
  IStepValueReferralStudent
} from "../components/Wizard/interfaces";
import query from "../utils/query";

const prepareStudent = (user: IPerson): IPerson => {
  return {
    ...user,
    latitude: user.latitude ? Number(user.latitude) : undefined,
    longitude: user.longitude ? Number(user.longitude) : undefined,
    max_budget: user.max_budget ? Number(user.max_budget) : undefined,
    min_area: user.min_area ? Number(user.min_area) : undefined
  };
};

class UserAPIClass {
  private readonly api: ServiceApi;

  constructor() {
    this.api = new ServiceApi(ServiceName.Protected);
  }

  getShortDescription(
    ids: string[]
  ): Promise<{ users_short_description: TUserShort[] }> {
    const userIdsQuery = ids.map((id) => `user_ids=${id}`).join("&");
    return this.api.get("users/short-description/?" + userIdsQuery);
  }

  getUser(id: string, options?: { [key: string]: any }): Promise<IPerson> {
    return this.api.get(`users/${id}`, options).then((user: IPerson) => {
      if (user.user_type === "student") {
        return prepareStudent(user);
      }
      return user;
    });
  }

  getUserPreview(id: string): Promise<Partial<IUserShort>> {
    return this.api.get(`users/${id}/user-preview`);
  }

  updateHost(userId: string, body: Partial<IPerson>): Promise<IPerson> {
    return this.api.patch(`users/host/${userId}`, body);
  }

  updateStudent(userId: string, body: Partial<IPerson>): Promise<IPerson> {
    return this.api.patch(`users/student/${userId}`, body);
  }

  delete(id: string): Promise<IResponseShort> {
    return this.api.del(`users/${id}`);
  }

  uploadAvatar(
    userId: string,
    image: File,
    { showNotificationOnError = true }
  ): Promise<IUploadAvatarResponse> {
    const formData = new FormData();
    formData.append("user_image", image);

    return this.api.post(`users/${userId}/upload-avatar`, formData, {
      Accept: "image/jpeg",
      showNotificationOnError
    });
  }

  requestVerification(userId: string, eventId: string): Promise<IPerson> {
    return this.api.post(`users/${userId}/request-verification/${eventId}`);
  }

  wizardStudentGetState(): Promise<IWizardStudentState> {
    return this.api.get("users-wizard/student/state", {
      showNotificationOnError: false
    });
  }

  wizardStudentSavePersonalInfo(
    value: TStepPayload<IStepValuePersonalInfo>
  ): Promise<IStepValuePersonalInfo> {
    return this.api.post("users-wizard/student/step/personal-info", value, {
      showNotificationOnError: false
    });
  }

  wizardStudentSavePersonalInfoPartially(
    value: TStepPayload<IStepValuePersonalInfo>
  ): Promise<IStepValuePersonalInfo> {
    return this.api.patch("users-wizard/student/step/personal-info", value, {
      showNotificationOnError: false
    });
  }

  wizardStudentSaveExpectations(
    value: TStepPayload<IStepValueExpectations>
  ): Promise<IStepValueExpectations> {
    return this.api.post("users-wizard/student/step/expectations", value, {
      showNotificationOnError: false
    });
  }

  wizardStudentSaveExpectationsPartially(
    value: TStepPayload<IStepValueExpectations>
  ): Promise<IStepValueExpectations> {
    return this.api.patch("users-wizard/student/step/expectations", value, {
      showNotificationOnError: false
    });
  }

  wizardStudentSaveAboutMyself(
    value: TStepPayload<IStepValueAboutMyself>
  ): Promise<IStepValueAboutMyself> {
    return this.api.post("users-wizard/student/step/about-myself", value, {
      showNotificationOnError: false
    });
  }

  wizardStudentSaveAboutMyselfPartially(
    value: TStepPayload<IStepValueAboutMyself>
  ): Promise<IStepValueAboutMyself> {
    return this.api.patch("users-wizard/student/step/about-myself", value, {
      showNotificationOnError: false
    });
  }

  wizardStudentSaveAvatar(value: {
    user_image: File;
  }): Promise<{ avatar_thumbnail_url: string; metadata: IWizardStepMetaData }> {
    const formData = new FormData();
    formData.append("user_image", value.user_image);

    return this.api.post("users-wizard/student/step/avatar", formData, {
      Accept: "image/jpeg",
      showNotificationOnError: false
    });
  }

  wizardStudentSaveMatchExpectations(
    value: TStepPayload<IStepValueMatchExpectations>
  ): Promise<IStepValueMatchExpectations> {
    return this.api.post(
      "users-wizard/student/step/match-expectations",
      value,
      {
        showNotificationOnError: false
      }
    );
  }

  wizardStudentSaveMatchExpectationsPartially(
    value: TStepPayload<IStepValueMatchExpectations>
  ): Promise<IStepValueMatchExpectations> {
    return this.api.patch(
      "users-wizard/student/step/match-expectations",
      value,
      {
        showNotificationOnError: false
      }
    );
  }

  wizardStudentSaveReferral(
    value: TStepPayload<IStepValueReferralStudent>
  ): Promise<IStepValueReferralStudent> {
    return this.api.post("users-wizard/student/step/referral", value, {
      showNotificationOnError: false
    });
  }

  wizardStudentSaveReferralPartially(
    value: TStepPayload<IStepValueReferralStudent>
  ): Promise<IStepValueReferralStudent> {
    return this.api.patch("users-wizard/student/step/referral", value, {
      showNotificationOnError: false
    });
  }

  wizardStudentSaveSearchPreferences(
    value: TStepPayload<IStepValueSearchPreferences>
  ): Promise<IStepValueSearchPreferences> {
    return this.api.post(
      "users-wizard/student/step/search-preferences",
      value,
      {
        showNotificationOnError: false
      }
    );
  }

  wizardStudentSaveSearchPreferencesPartially(
    value: TStepPayload<IStepValueSearchPreferences>
  ): Promise<IStepValueSearchPreferences> {
    return this.api.patch(
      "users-wizard/student/step/search-preferences",
      value,
      {
        showNotificationOnError: false
      }
    );
  }

  wizardStudentReset(userId: string): Promise<IResponseShort> {
    return this.api.del(`users-wizard/internal/reset/${userId}`);
  }

  wizardStudentFinish(): Promise<IResponseShort> {
    return this.api.post("users-wizard/student/finish");
  }

  wizardHostGetState(): Promise<IWizardHostState> {
    return this.api.get("users-wizard/host/state", {
      showNotificationOnError: false
    });
  }

  wizardHostSavePersonalInfo(
    value: TStepPayload<IStepValuePersonalInfo>
  ): Promise<IStepValuePersonalInfo> {
    return this.api.post("users-wizard/host/step/personal-info", value, {
      showNotificationOnError: false
    });
  }

  wizardHostSavePersonalInfoPartially(
    value: TStepPayload<IStepValuePersonalInfo>
  ): Promise<IStepValuePersonalInfo> {
    return this.api.patch("users-wizard/host/step/personal-info", value, {
      showNotificationOnError: false
    });
  }

  wizardHostSaveAvatar(value: {
    user_image: File | null;
  }): Promise<{ avatar_thumbnail_url: string; metadata: IWizardStepMetaData }> {
    if (value.user_image !== null) {
      const formData = new FormData();
      formData.append("user_image", value.user_image);
      return this.api.post("users-wizard/host/step/avatar", formData, {
        Accept: "image/jpeg",
        showNotificationOnError: false
      });
    }
    return this.api.post("users-wizard/host/step/avatar", {
      showNotificationOnError: false
    });
  }

  // wizardHostSavePlanIntake(
  //   value: TStepPayload<IStepValuePlanIntake>
  // ): Promise<IStepValuePlanIntake> {
  //   return this.api.post("users-wizard/host/step/plan-intake", value);
  // }

  wizardHostSaveReferral(
    value: TStepPayload<IStepValueReferral>
  ): Promise<IStepValueReferral> {
    return this.api.post("users-wizard/host/step/referral", value);
  }

  wizardHostSaveReferralPartially(
    value: TStepPayload<IStepValueReferral>
  ): Promise<IStepValueReferral> {
    return this.api.patch("users-wizard/host/step/referral", value);
  }

  wizardHostReset(userId: string): Promise<IResponseShort> {
    return this.api.del(`users-wizard/internal/reset/${userId}`);
  }

  wizardHostFinish(): Promise<IResponseShort> {
    return this.api.post("users-wizard/host/finish");
  }

  // Reviews:
  getReviews(hostId: string): Promise<TReview[]> {
    return this.api.get("reviews/read-by-host/" + hostId);
  }
  getReview(hostId: string, created_at: string): Promise<TReview> {
    return this.api.get(
      `reviews/read-by-host/${hostId}/read-one?` +
        query.stringify({ created_at })
    );
  }
}

export const UserAPI = new UserAPIClass();
