import { TWizardStepComponentStatic } from "../../../interfaces";
import { forwardRef } from "react";
import styled from "styled-components/macro";
import { useTranslation } from "react-i18next";
import { DefaultFont } from "../../../../../styles/fonts";
import FounderImage from "assets/images/wizard/WizardStepPhoto.webp";
import FounderImageMobile from "assets/images/wizard/WizardStepPhotoMobile.webp";
import { CheckCircle } from "../../../../Icons/CheckCircle";
import { RoleUser } from "../../../../../interfaces/interfaces";
import { useIsMobile } from "../../../../../utils/hooks/useWindowSize";

const MainContentTitle = styled.div`
  font-size: ${({ theme }) => theme.text.header.size};
  font-family: ${({ theme }) => theme.text.family};
  line-height: ${({ theme }) => theme.text.defaultLineHeight};
  font-weight: 500;
  margin-bottom: 32px;
  color: ${({ theme }) => theme.colors.hardGray};

  @media ${({ theme }) => theme.media.phone} {
    font-size: ${({ theme }) => theme.text.header.mobileSize};
  }
`;
const Intro = styled.div`
  color: ${(p) => p.theme.colors.hardGray};
  white-space: pre-wrap;
`;
const ImageContainer = styled.div`
  margin-top: 32px;
  padding: 16px;
  background: white;
  width: 100%;
  border-radius: 8px;
`;
const Image = styled.img`
  width: 100%;
`;

const Sidebar = styled.div`
  width: 300px;
  flex-shrink: 0;
  padding: 40px;
  background: white;
`;
const SidebarTitle = styled.div`
  ${DefaultFont};
  font-size: 18px;
  margin-bottom: 40px;
  color: ${(p) => p.theme.colors.hardGray};
`;
const Benefits = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
const Benefit = styled.div`
  font-size: ${({ theme }) => theme.font.size};
  font-family: ${({ theme }) => theme.text.family};
  line-height: ${({ theme }) => theme.text.defaultLineHeight};
  display: flex;
  align-items: center;
  gap: 16px;
  color: ${({ theme }) => theme.colors.midGrey};

  svg {
    flex-shrink: 0;
  }
`;

const Root = styled.div`
  display: flex;
  gap: 80px;
  align-items: flex-start;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 32px;
    padding-top: 40px;
    padding-bottom: 40px;
    height: 100%;

    ${Sidebar} {
      width: 100%;
    }
  }
`;
export const WizardStepWelcome: TWizardStepComponentStatic<any> = forwardRef(
  () => {
    return <WizardStepWelcomeView userType="student" />;
  }
);

export const WizardStepWelcomeView = ({ userType }: { userType: RoleUser }) => {
  const { t } = useTranslation(userType);
  const isMobile = useIsMobile();

  return (
    <Root>
      <div>
        <MainContentTitle>
          {t("wizard.steps.Welcome.mainTitle")}
        </MainContentTitle>
        <Intro>{t("wizard.steps.Welcome.introText")}</Intro>
        <ImageContainer>
          <Image src={!isMobile ? FounderImage : FounderImageMobile} alt="" />
        </ImageContainer>
      </div>
      <Sidebar>
        <SidebarTitle>{t("wizard.steps.Welcome.sidebar.title")}</SidebarTitle>
        <Benefits>
          {(
            t("wizard.steps.Welcome.sidebar.benefitsList", {
              returnObjects: true
            }) as string[]
          ).map((text, index) => (
            <Benefit key={index}>
              <CheckCircle />
              {text}
            </Benefit>
          ))}
        </Benefits>
      </Sidebar>
    </Root>
  );
};
