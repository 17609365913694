import {
  IChat,
  IFulfilledChat,
  MessageTypeNames,
  TMessage
} from "../interfaces/interfacesChat";
import { TUserShort } from "../interfaces/interfaces";
import { parseServerDateTime, sortByCreationTime } from "./time";

export const getChatPartnerIdField = (userType: string) =>
  userType === "host" ? "student_id" : "host_id";

export const getChatMyIdField = (userType: string) =>
  userType === "host" ? "host_id" : "student_id";

export const getChatPartnerId = (chat: IChat, userType: string) => {
  return chat[getChatPartnerIdField(userType)];
};

export const getChatReadAtField = (userType: string) =>
  userType === "host"
    ? "host_most_recent_read_at"
    : "student_most_recent_read_at";

export const getChatRemoveByField = (userType: string) =>
  userType === "host" ? "removed_by_host" : "removed_by_student";

export const sortChatsByUpdateTime = (a: IFulfilledChat, b: IFulfilledChat) => {
  return parseServerDateTime(a.updated_at).getTime() <
    parseServerDateTime(b.updated_at).getTime()
    ? 1
    : -1;
};

export const sortChats = (chats: IFulfilledChat[]) => {
  return chats.sort(sortChatsByUpdateTime);
};

export const prepareChatMessages = (
  messages: TMessage[],
  userRole: string | null
) => {
  return messages
    .filter((m) => {
      return (
        m.msg_type !== MessageTypeNames.REMOVE_CHAT &&
        m.msg_type !== MessageTypeNames.UNREMOVE_CHAT &&
        !(
          userRole === "student" &&
          m.msg_type === MessageTypeNames.REVIEW_CREATED
        )
      );
    })
    .sort(sortByCreationTime);
};

export const createFulfilledChats = (
  chats: IChat[],
  usersDescriptions: TUserShort[],
  userType: string
): IFulfilledChat[] => {
  const usersMap = Object.fromEntries(
    usersDescriptions.map<[string, TUserShort]>((item: TUserShort) => [
      item.user_id,
      {
        user_id: item.user_id,
        first_name: item.first_name!,
        last_name: item.last_name!,
        thumbnail_url: item.thumbnail_url!,
        account_type: item.account_type
      }
    ])
  );

  return chats
    .filter((chat) => Boolean(usersMap[getChatPartnerId(chat, userType)]))
    .map((chat) => {
      return {
        ...chat,
        conversation_partner_short: usersMap[getChatPartnerId(chat, userType)]
      } as IFulfilledChat;
    });
};
