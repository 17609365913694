import {
  ReactElement,
  createElement,
  useEffect,
  useRef,
  useState
} from "react";
import {
  IWizardMetaData,
  TWizardStepComponentRef,
  TStepConfiguration,
  TWizardStepComponentEditable,
  TWizardStepComponentStatic,
  TWizardStepComponentView,
  WizardStepComponentType,
  TWizardStepComponentMultiEditable,
  TWizardType
} from "./interfaces";
import { LangBubble } from "../LangBubble";
import { Button, Tooltip } from "antd";
import { usePromisesLoader } from "../../utils/usePromiseLoader";
import { useContextHospi } from "../../context/ContextHospi";
import { useNotifier } from "../../context/ContextNotifier";
import { getActiveStepNumber } from "./utils";
import { useTranslation } from "react-i18next";
import {
  StepContainerDefault,
  StepTitle,
  StepDescription,
  LoadingOverlay,
  Root,
  Header,
  DebugInfo,
  HeaderButtons,
  Progress,
  StepContainer,
  Footer,
  LoadingOverlayText
} from "./Student/styles";
import { theme } from "../../styles/theme";
import { LoadingIndicator } from "../LoadingIndicator";
import { useHospiNavigate } from "../../utils/common";
import { IResponseShort } from "../../interfaces/interfaces";
import { EditableStepWrapper } from "./components";

export const Wizard = <
  TStep extends string,
  TStepPath extends string,
  TState extends {
    metadata: IWizardMetaData<TStepPath>;
  } & {
    [key in TStepPath]?: any;
  },
  TStepPathsInState extends {
    [key in TStep]?: TStepPath;
  }
>({
  wizardType,
  stepsOrder,
  stepsPathsInState,
  stepsConfiguration,
  defaultState,
  getWizardState,
  resetWizardState,
  finishWizard,
  getStepTitle,
  getStepDescription,
  getOverviewStepTitle,
  onFinished,
  additionalStepContent,
  getProgressPercent
}: {
  stepsOrder: TStep[];
  stepsPathsInState: TStepPathsInState;
  stepsConfiguration: {
    [key in TStep]?: TStepConfiguration<TState, TStep>;
  };
  defaultState: TState;
  getWizardState: () => Promise<TState>;
  resetWizardState: (id: string) => Promise<IResponseShort>;
  finishWizard: () => Promise<IResponseShort>;
  getStepTitle: (stepName: TStep) => string;
  getStepDescription: (stepName: TStep) => string;
  getOverviewStepTitle?: (stepName: TStep) => string;
  onFinished?: () => void | Promise<any>;
  additionalStepContent?: ReactElement;
  wizardType?: TWizardType;
  getProgressPercent?: (stepName: TStep) => number | undefined;
}) => {
  const [wizardState, setWizardState] = useState<TState | null>(null);
  const [displayStepNumber, setDisplayStepNumber] = useState<null | number>(
    null
  );
  const displayStepRef = useRef<TWizardStepComponentRef<TState>>(null);
  const { isLoading: isInitialLoading, watchPromise: watchInitialPromise } =
    usePromisesLoader();
  const { isLoading, watchPromise } = usePromisesLoader();
  const {
    isLoading: isLoadingResetWizard,
    watchPromise: watchPromiseResetWizard
  } = usePromisesLoader();
  const {
    isLoading: isLoadingSaveAndExit,
    watchPromise: watchPromiseSaveAndExit
  } = usePromisesLoader();
  const [loadWizardStateError, setLoadWizardStateError] = useState<
    null | string
  >(null);
  const [multiStepInEditMode, setMultiStepInEditMode] = useState(false);
  const { currentUserId } = useContextHospi();
  const notifier = useNotifier();
  const { t, i18n } = useTranslation("wizard");
  const navigate = useHospiNavigate();
  const stepContainerRef = useRef<HTMLDivElement | null>(null);

  const stepsOrderRef = useRef(stepsOrder);
  const stepsPathsInStateRef = useRef(stepsPathsInState);
  const stepsConfigurationRef = useRef(stepsConfiguration);
  const defaultStateRef = useRef(defaultState);
  const getWizardStateRef = useRef(getWizardState);

  if (
    !stepsOrderRef.current ||
    !stepsPathsInStateRef.current ||
    !stepsConfigurationRef.current ||
    !defaultStateRef.current ||
    !getWizardStateRef.current
  ) {
    throw Error("Wizard props are not valid");
  }

  // load initial wizard state
  useEffect(() => {
    watchInitialPromise(
      getWizardStateRef
        .current()
        .then((wizard) => {
          const stateWithDefaults = { ...wizard };

          stepsOrderRef.current.forEach((step) => {
            const path = stepsPathsInStateRef.current[step] as TStepPath;
            if (
              defaultStateRef.current[path] &&
              stateWithDefaults[path] === null
            ) {
              stateWithDefaults[path] = defaultStateRef.current[path];
            }
          });

          setWizardState(stateWithDefaults);
          const stepNumber = getActiveStepNumber<
            TStep,
            TStepPath,
            TState,
            TStepPathsInState
          >(
            stateWithDefaults,
            stepsPathsInStateRef.current,
            stepsOrderRef.current
          );
          setDisplayStepNumber(stepNumber);
        })
        .catch((e) => {
          if (e.response.status === 404) {
            setWizardState(defaultStateRef.current);
            setDisplayStepNumber(0);
          } else {
            setLoadWizardStateError(e.toString());
          }
        })
    );
  }, [watchInitialPromise]);

  if (loadWizardStateError) {
    return <LoadingOverlay>Load wizard error. Reload the page</LoadingOverlay>;
  }

  if (displayStepNumber !== null && wizardState !== null && !isInitialLoading) {
    const stepName = stepsOrderRef.current[displayStepNumber];
    const stepConfiguration = stepsConfigurationRef.current[stepName]!;

    if (!stepConfiguration) {
      throw Error(
        `no stepConfiguration displayStepNumber:${displayStepNumber} stepName:${stepName}`
      );
    }

    const { component, container, type, nextButtonText } = stepConfiguration;

    const handleBack = () => {
      const possibleUpdatedState =
        displayStepRef.current?.getPossibleUpdatedState?.();
      if (possibleUpdatedState) {
        setWizardState(possibleUpdatedState);
      }

      setDisplayStepNumber((prev) => {
        if (prev !== null) {
          return prev - 1;
        }

        return null;
      });
    };

    const handleContinue = async () => {
      // save current step and show next step
      let canGoToNextStep = true;
      if (displayStepRef.current?.save) {
        const savePromise = displayStepRef.current
          .save()
          .then((updatedState) => {
            const isUpdateState = updatedState !== null;
            canGoToNextStep = isUpdateState;
            if (isUpdateState) setWizardState(updatedState);
            return isUpdateState;
          })
          .then((isStateUpdated) => {
            if (isStateUpdated)
              getWizardState().then((wizard) => {
                setWizardState((prev) => {
                  if (prev === null) return null;
                  return {
                    ...prev,
                    metadata: wizard.metadata
                  };
                });
              });
          })
          .catch((e) => {
            if (e?.response?.data?.message === "Wizard already finished") {
              notifier.error({
                message: t("wizardAlreadyFinished")
              });
              navigate(`/${i18n.language}/`);
            }
            return Promise.reject(e);
          });
        watchPromise(savePromise);
        await savePromise;
      }

      if (wizardType === "host" && displayStepNumber === 0)
        window.dataLayer.push({
          event: "Account_confirmation_host",
          user_id: currentUserId,
          user_type: "host"
        });

      // if (wizardType === "house") {
      // if (stepName === "WelcomePublish")
      //   window.dataLayer.push({
      //     event: "Host_search_pref_3.1_start",
      //     user_id: currentUserId,
      //     user_type: "host"
      //     house_id: wizardState
      //       ? (wizardState as unknown as IWizardHouseState).house_id
      //       : ""
      //   });
      //   stepName === "PublishOverview" &&
      //     window.dataLayer.push({
      //       event: "Host_search_pref_3.6_search_pref_overview",
      //       user_id: currentUserId,
      //       user_type: "host",
      //       house_id: wizardState
      //         ? (wizardState as unknown as IWizardHouseState).house_id
      //         : ""
      //     });
      // }

      if (displayStepNumber === stepsOrderRef.current.length - 1) {
        watchPromise(
          finishWizard().then((_) => {
            return onFinished?.();
          })
        );
      } else {
        if (canGoToNextStep) {
          setDisplayStepNumber((prev) => {
            if (prev === null) {
              return null;
            }
            return prev + 1;
          });
          stepContainerRef.current?.scrollTo({
            top: 0,
            left: 0
          });
        }
      }
    };
    const handleSaveAndExit = () => {
      if (displayStepRef.current?.savePartially) {
        watchPromiseSaveAndExit(
          displayStepRef.current.savePartially().then((updatedState) => {
            setWizardState(updatedState);
            navigate(`/${i18n.language}/`);
          })
        );
      } else {
        navigate(`/${i18n.language}/`);
      }
    };

    const handleResetWizard = () => {
      watchPromiseResetWizard(
        resetWizardState(currentUserId!).then(() => {
          setWizardState(defaultStateRef.current);
          setDisplayStepNumber(0);
          notifier.info({
            message: "Wizard was reset"
          });
        })
      );
    };

    const stepInnerContainer = container ?? StepContainerDefault;

    const title = getStepTitle(stepName);
    const description = getStepDescription(stepName);
    const progressBarPercent: number | undefined =
      typeof getProgressPercent === "function"
        ? getProgressPercent(stepName)
        : stepsOrderRef.current.indexOf(stepName) === 0
        ? 0
        : (stepsOrderRef.current.indexOf(stepName) /
            stepsOrderRef.current.length) *
          100;

    return (
      <Root>
        <Header>
          <DebugInfo style={{ display: "none" }}>
            Step {displayStepNumber + 1} of {stepsOrderRef.current.length}.{" "}
            {stepName}
          </DebugInfo>

          <HeaderButtons>
            <LangBubble />
            <Button
              onClick={handleSaveAndExit}
              size="large"
              disabled={isLoading || isLoadingResetWizard}
              loading={isLoadingSaveAndExit}
              style={{ color: theme.colors.midGrey }}
            >
              {t("buttonSaveAndExit")}
            </Button>

            <Button
              onClick={handleResetWizard}
              danger
              size="large"
              disabled={isLoading || isLoadingSaveAndExit}
              loading={isLoadingResetWizard}
              style={{
                display: "none"
              }}
            >
              Reset Wizard
            </Button>
          </HeaderButtons>
        </Header>
        <Progress
          percent={progressBarPercent}
          showInfo={false}
          strokeColor={theme.colors.primary}
        />
        <StepContainer ref={stepContainerRef}>
          <>
            {createElement(
              stepInnerContainer,
              {},
              <>
                {title ? (
                  <StepTitle
                    $mb={
                      wizardType === "house" &&
                      stepName === "FullFinishConfig" &&
                      displayStepNumber === 12
                        ? "1rem"
                        : "0.5rem"
                    }
                    dangerouslySetInnerHTML={{
                      __html: title.replaceAll("\n", "<br/>")
                    }}
                  />
                ) : null}
                {description ? (
                  <StepDescription
                    $fw={
                      wizardType === "house" &&
                      stepName === "FullFinishConfig" &&
                      displayStepNumber === 12
                        ? "weightSemiMedium"
                        : "weightNormal"
                    }
                    dangerouslySetInnerHTML={{
                      __html: description.replaceAll("\n", "<br/>")
                    }}
                  />
                ) : null}
                {type === WizardStepComponentType.Static
                  ? createElement(
                      component as TWizardStepComponentStatic<TState>,
                      {
                        ref: displayStepRef
                      }
                    )
                  : null}
                {type === WizardStepComponentType.View
                  ? createElement(
                      component as TWizardStepComponentView<TState>,
                      {
                        state: wizardState,
                        ref: displayStepRef
                      }
                    )
                  : null}
                {type === WizardStepComponentType.Editable ? (
                  <EditableStepWrapper>
                    {createElement(
                      component as TWizardStepComponentEditable<TState>,
                      {
                        state: wizardState,
                        ref: displayStepRef
                      }
                    )}
                  </EditableStepWrapper>
                ) : null}
                {type === WizardStepComponentType.MultiEditable &&
                typeof getOverviewStepTitle === "function"
                  ? createElement(
                      component as TWizardStepComponentMultiEditable<
                        TState,
                        TStep
                      >,
                      {
                        stepsOrder: stepsOrder,
                        stepsConfiguration: stepsConfiguration,
                        state: wizardState,
                        onEditModeChange: (mode) => {
                          if (mode === "Edit") {
                            setMultiStepInEditMode(true);
                          } else {
                            setMultiStepInEditMode(false);
                          }
                        },
                        onStateChange: (updatedState) => {
                          setWizardState(updatedState);
                        },
                        getStepTitle: getOverviewStepTitle,
                        ref: displayStepRef
                      }
                    )
                  : null}
              </>
            )}
            {typeof additionalStepContent !== "undefined" &&
              (wizardType === "house" ||
                (wizardType === "host" && displayStepNumber === 1)) &&
              additionalStepContent}
          </>
        </StepContainer>
        <Footer>
          {displayStepNumber > 0 ? (
            <Tooltip
              title={
                multiStepInEditMode ? t("saveEditedStepsBeforeGoBack") : null
              }
            >
              <Button
                onClick={handleBack}
                disabled={
                  isLoading ||
                  isLoadingResetWizard ||
                  isLoadingSaveAndExit ||
                  multiStepInEditMode
                }
                size="large"
              >
                {t("buttonBack")}
              </Button>
            </Tooltip>
          ) : null}
          <div
            style={{
              flexGrow: "1"
            }}
          ></div>
          <Tooltip
            title={
              multiStepInEditMode ? t("saveEditedStepsBeforeGoNext") : null
            }
          >
            <Button
              onClick={handleContinue}
              type="primary"
              loading={isLoading}
              disabled={
                isLoadingResetWizard ||
                isLoadingSaveAndExit ||
                multiStepInEditMode
              }
              size="large"
              id={`${stepName}ContinueButton`}
            >
              {!!nextButtonText?.length
                ? t(nextButtonText)
                : t("buttonContinue")}
            </Button>
          </Tooltip>
        </Footer>
      </Root>
    );
  }

  return (
    <LoadingOverlay>
      <LoadingOverlayText>{t("wizardLoading")}</LoadingOverlayText>
      <LoadingIndicator fontSize={32} />
    </LoadingOverlay>
  );
};
