import { InputHTMLAttributes } from "react";
import styled from "styled-components/macro";

export const StyledContent = styled.p`
  font-size: 1.125rem;
  font-weight: ${({ theme }) => theme.font.weightMedium};
  margin-left: 0.3em;
  color: ${(props) => props.theme.colors.text};

  @media (max-width: 425px) {
    font-size: 1rem;
  }
`;

export const HidenInput = styled.input<InputHTMLAttributes<HTMLInputElement>>`
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  clip: rect(0 0 0 0);
  overflow: hidden;
`;

export const Sign = styled.svg.attrs((props) => ({
  fill: props.fill || "none"
}))`
  stroke: ${(props) => props.theme.colors.place};
  stroke-width: 3px;
  width: 20px;
`;

export const BoxContainer = styled.label<{ h?: string; p?: string }>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: ${(props) => props.h || "initial"};
  cursor: pointer;
  padding: ${(props) => props.p || props.theme.sizes.input.padding};
  position: relative;
`;

interface IBox extends InputHTMLAttributes<HTMLInputElement> {
  readonly checked?: boolean;
  readonly type?: string;
  h?: string;
}
export const StyledBox = styled.div<IBox>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${(props) => props.theme.font.size};
  height: ${(props) => props.theme.font.size};
  background-color: ${(props) =>
    props.checked ? props.theme.colors.primary : props.theme.colors.white};
  border: 1px solid
    ${(props) =>
      props.checked ? props.theme.colors.primary : props.theme.colors.border};
  border-radius: ${(props) =>
    props.type === "checkbox" ? props.theme.sizes.borderRadius : "50%"};
  transition: all 150ms;
  ${Sign} {
    visibility: ${(props) => (props.checked ? "visible" : "hidden")};
  }
`;
