import styled, { css } from "styled-components/macro";

const maxWidthRootPages = "50rem";

export const HomeDiv = styled.div`
  display: block;
  position: relative;
  width: 100%;
  text-align: center;
  margin: 0 auto 0;
  background-color: ${({ theme }) => theme.colors.bgSecondary};
  & > section {
    margin: 0 auto 2rem;
    & > div > button {
      font-size: 24px;
    }
    @media ${(props) => props.theme.media.phone} {
      margin: 0 auto 2rem;
    }
  }
`;

export const LimitWidthSection = styled.section`
  max-width: ${maxWidthRootPages};
  width: 100%;
  padding: 1rem;
`;

export const PictureLink = styled.a<{ logo: string; bgSize?: string }>`
  display: block;
  background: url(${(props) => props.logo}) center/contain no-repeat;
  height: 10rem;
  ${(props) =>
    props.bgSize &&
    css`
      background-size: ${props.bgSize};
    `}
`;

export const ItemLink = styled.a`
  font-size: 18px;
  color: ${(props) => props.theme.colors.primary};
  text-align: left;
`;

export const LiItem = styled.span`
  font-size: 18px;
  color: ${({ theme }) => theme.colors.text};
  margin: 0;
  &:before {
    content: "\\2022";
    color: ${({ theme }) => theme.colors.primary};
    width: 1em;
    margin: 0 14px;
  }
`;
