import { Item, Wrapper } from "./styles";
import { useTranslation } from "react-i18next";

export const Statistics = () => {
  const { t } = useTranslation("home");

  const statisticData = [
    {
      count: "3500",
      title: t("statistic.registeredHosts")
    },
    {
      count: "50.000",
      title: t("statistic.activeStudents")
    },
    {
      count: "1000",
      title: t("statistic.successMatches")
    },
    {
      count: "185",
      title: t("statistic.nationalities")
    }
  ];

  return (
    <Wrapper>
      <div>
        {statisticData.map(({ count, title }, i) => (
          <Item key={i}>
            <div>
              <p>{count}+ </p>
              <p id="bold">{title}</p>
              {i !== 0 && <div />}
            </div>
          </Item>
        ))}
      </div>
    </Wrapper>
  );
};
