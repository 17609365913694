import { ChangeEvent } from "react";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { ButtonSection, ListItem, ListSection, Wrapper } from "./styles";
import { StyledButton } from "../generic";
import { Checkbox } from "../CheckRadiobox";
import { useTranslation } from "react-i18next";

export const CookieBanner = () => {
  const { t, i18n } = useTranslation("cookies");
  const [_, setCookie] = useCookies(["personalised", "marketing", "accepted"]);
  const [cookiesState, setCookiesState] = useState({
    essential: true,
    personalised: true,
    marketing: true
  });

  const [open, setOpen] = useState<boolean>(false);
  const [bannerOptions, setBannerOptions] = useState<boolean>(false);

  useEffect(() => {
    if (window.localStorage.getItem("areCookiesAccepted") !== "true") {
      setCookiesState({
        essential: true,
        personalised: true,
        marketing: true
      });
      setBannerOptions(false);
      setOpen(true);
    }
  }, [window.localStorage.getItem("areCookiesAccepted")]);

  const setCookieHandler = () => {
    window.localStorage.setItem("areCookiesAccepted", "true");
    setCookie("personalised", cookiesState["personalised"]);
    setCookie("marketing", cookiesState["marketing"]);
    setCookie("accepted", "true");
    setOpen(false);
  };

  const selectedItem = (checked: boolean) => {
    return {
      onChange: (e: ChangeEvent<HTMLInputElement>) => {
        setCookiesState((prev) => ({
          ...prev,
          [e.target.name]: !prev[e.target.name as keyof typeof cookiesState]
        }));
      },
      checked
    };
  };

  return (
    <Wrapper open={open}>
      {bannerOptions ? (
        <>
          <ListSection>
            <ListItem>
              <Checkbox
                key={"essential"}
                name={"essential"}
                disabled={true}
                {...selectedItem(
                  cookiesState["essential" as keyof typeof cookiesState]
                )}
                p="0 8px 0 0"
              >
                {t("cookieBanner.title1")}
              </Checkbox>
              <p>{t("cookieBanner.desc1")}</p>
            </ListItem>
            <ListItem>
              <Checkbox
                key={"personalised"}
                name={"personalised"}
                {...selectedItem(
                  cookiesState["personalised" as keyof typeof cookiesState]
                )}
                p="0 8px 0 0"
              >
                {t("cookieBanner.title2")}
              </Checkbox>
              <p>{t("cookieBanner.desc2")}</p>
            </ListItem>
            <ListItem>
              <Checkbox
                key={"marketing"}
                name={"marketing"}
                {...selectedItem(
                  cookiesState["marketing" as keyof typeof cookiesState]
                )}
                p="0 8px 0 0"
              >
                {t("cookieBanner.title3")}
              </Checkbox>
              <p>{t("cookieBanner.desc3")}</p>
            </ListItem>
          </ListSection>
          <ButtonSection>
            <StyledButton outline onClick={() => setBannerOptions(false)}>
              {t("cookieBanner.back")}
            </StyledButton>
            <StyledButton onClick={setCookieHandler}>
              {t("cookieBanner.agree")}
            </StyledButton>
          </ButtonSection>
        </>
      ) : (
        <>
          <a href={`/${i18n.language}/cookies`} target="_blank">
            {t("cookieBanner.text.p1")} <b>{t("cookieBanner.text.p2")}</b>
          </a>
          <ButtonSection>
            <StyledButton outline onClick={() => setBannerOptions(true)}>
              {t("cookieBanner.settings")}
            </StyledButton>
            <StyledButton onClick={setCookieHandler}>
              {t("cookieBanner.agree")}
            </StyledButton>
          </ButtonSection>
        </>
      )}
    </Wrapper>
  );
};
