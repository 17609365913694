import styled from "styled-components/macro";
import { theme } from "../../styles/theme";
import { MixinFloatElement } from "../../components/mixins";
import { Label } from "../../components/generic";

export const Wrapper = styled.div`
  position: relative;
  &:not(:nth-last-child(1)) {
    border-bottom: 1px solid #e5e7eb;
  }
  padding: 25px 0;
`;

export const Item = styled.div<{ open: boolean }>`
  position: relative;
  cursor: pointer;
  width: 100%;
  text-align: left;
  border: none;
  outline: none;
  transition: 0.3s;
  color: ${({ open }) => (open ? theme.colors.text : theme.colors.textDimmed)};
  font-size: 20px;
  padding-right: 1.6em;
  margin-bottom: ${({ open }) => (open ? "0.5em" : 0)};

  & svg {
    color: ${({ open }) =>
      open ? theme.colors.text : theme.colors.textDimmed};
  }
  &:hover {
    color: ${theme.colors.text};
    & svg {
      color: ${theme.colors.text};
    }
  }
  & div {
    position: absolute;
    border-left: 2px solid #9ca3af;
    border-top: 2px solid #9ca3af;
    border-radius: 2px;
    width: 14px;
    height: 14px;
    transform: rotate(${(props) => (props.open ? "45" : "225")}deg);
    right: 0;
    top: 0;
    transition: 0.3s;
  }
`;

export const Content = styled.div<{ open: boolean; height: number }>`
  color: ${theme.colors.textDimmed};
  max-height: ${(props) => (props.open ? props.height : "0")}px;
  overflow: hidden;
  transition: 0.3s;
  font-size: 18px;
`;

export const HiddenContent = styled.div`
  font-size: 18px;
  opacity: 0;
  position: absolute;
`;

export const StyledPrefix = styled(Label)`
  ${MixinFloatElement};
  right: ${(props) => props.theme.sizes.input.padding};
  padding: 0;
`;
