import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  IMatch,
  IBaseMatchData,
  IUpdateCheckoutDatePayload,
  TMatchData,
  TReview,
  TBaseReview,
  ISavedSearchPreference,
  ISearchPreferencePayload
} from "../../interfaces/interfaces";
import { StudentAPI } from "../studentApi";
import { getHouseQueryKey, getHousesQueryKey } from "./houseApiQueries";
import { useContextHospi } from "../../context/ContextHospi";
import { getReviewsQueryKey } from "./userApiQueries";
import { useContextStudentSearches } from "../../context/ContextStudentSearches";
import { sortByCreationTime } from "../../utils/time";

export const useStudentAcceptInvite = () => {
  const queryClient = useQueryClient();
  const { cognitoUser } = useContextHospi();
  return useMutation<unknown, unknown, IBaseMatchData>({
    mutationFn: (payload) => StudentAPI.acceptInvite(payload),
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({
        queryKey: getStudentMatchesQueryKey({ userId: variables.student_id })
      });
      queryClient.invalidateQueries({
        queryKey: getMatchQueryKey({
          studentId: variables.student_id,
          hostId: variables.host_id,
          houseId: variables.house_id
        })
      });
      queryClient.invalidateQueries({
        queryKey: getHouseQueryKey(variables.house_id)
      });
      queryClient.invalidateQueries({
        queryKey: getHousesQueryKey(cognitoUser?.id!)
      });
    }
  });
};

export const getMatchQueryKey = (params: {
  studentId?: string;
  hostId?: string;
  houseId?: string;
}) => ["match", JSON.stringify(params)];

export const useGetMatch = (
  studentId?: string,
  hostId?: string,
  houseId?: string,
  enabled?: boolean
) => {
  return useQuery(
    getMatchQueryKey({ studentId, hostId, houseId }),
    () => {
      if (!studentId || !hostId || !houseId) {
        return;
      }

      return StudentAPI.getMatch(studentId, hostId, houseId, {
        showNotificationOnError: false
      }).catch((e) => {
        if (e?.response?.status === 404) {
          return {
            student_id: studentId,
            host_id: hostId,
            house_id: houseId,
            match_status: undefined,
            contract_id: null,
            contract_status: null,
            payment_status: null,
            payment_link: null
          } as IMatch;
        }
        return Promise.reject(e);
      });
    },
    {
      enabled: Boolean(studentId && hostId && houseId) && enabled !== false
    }
  );
};

export const getStudentMatchesQueryKey = ({ userId }: { userId?: string }) => [
  "studentMatches",
  userId
];

export const useGetStudentMatches = (
  userId?: string,
  enabled: boolean = true
) => {
  return useQuery(
    getStudentMatchesQueryKey({ userId }),
    () => {
      if (!userId) {
        return;
      }

      return StudentAPI.getMatches(userId);
    },
    {
      enabled: Boolean(userId) && enabled !== false
    }
  );
};

export const useShowInterest = () => {
  const queryClient = useQueryClient();

  return useMutation<
    unknown,
    unknown,
    { match: IBaseMatchData; message: string }
  >({
    mutationFn: (payload) =>
      StudentAPI.showInterest(payload.match, payload.message),
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({
        queryKey: getMatchQueryKey({
          studentId: variables.match.student_id,
          hostId: variables.match.host_id,
          houseId: variables.match.house_id
        })
      });
      queryClient.invalidateQueries({
        queryKey: getStudentMatchesQueryKey({
          userId: variables.match.student_id
        })
      });
    }
  });
};

export const useRejectInvite = () => {
  const queryClient = useQueryClient();

  return useMutation<unknown, unknown, IBaseMatchData>({
    mutationFn: (payload) => StudentAPI.rejectInvite(payload),
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({
        queryKey: getMatchQueryKey({
          studentId: variables.student_id,
          hostId: variables.host_id,
          houseId: variables.house_id
        })
      });
      queryClient.invalidateQueries({
        queryKey: getStudentMatchesQueryKey({
          userId: variables.student_id
        })
      });
    }
  });
};

export const useUpdateCheckoutDate = () => {
  const queryClient = useQueryClient();

  return useMutation<unknown, unknown, IUpdateCheckoutDatePayload>({
    mutationFn: (payload) => StudentAPI.updateCheckoutDate(payload),
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({
        queryKey: getMatchQueryKey({
          studentId: variables.student_id,
          hostId: variables.host_id,
          houseId: variables.house_id
        })
      });

      queryClient.setQueryData<TMatchData[]>(
        getStudentMatchesQueryKey({ userId: variables.student_id }),
        (oldData) => {
          if (oldData) {
            return oldData.map((m) => {
              if (
                m.student_id === variables.student_id &&
                m.house_id === variables.house_id &&
                m.host_id === variables.host_id
              ) {
                return {
                  ...m,
                  check_out_date: variables.new_check_out_date,
                  checkout_date_actor: "student"
                };
              } else return m;
            });
          }
        }
      );
    }
  });
};

export const useCreateReview = () => {
  const queryClient = useQueryClient();

  return useMutation<
    TReview,
    unknown,
    {
      payload: Pick<TReview, "rating" | "review_text" | "house_id">;
      hostId: string;
    }
  >({
    mutationFn: ({ payload, hostId }) =>
      StudentAPI.createReview(payload, hostId),
    onSuccess: (data, { hostId }) => {
      queryClient.setQueryData<TReview[]>(
        getReviewsQueryKey({ hostId }),
        (oldData) => {
          if (oldData) {
            return [...oldData, data];
          }
        }
      );
    }
  });
};

export const useUpdateReview = () => {
  const queryClient = useQueryClient();

  return useMutation<
    unknown,
    unknown,
    {
      payload: Partial<TBaseReview> & { created_at: string };
      hostId: string;
      houseId: string;
    }
  >({
    mutationFn: ({ payload, hostId }) =>
      StudentAPI.updateReview(payload, hostId),
    onSuccess: (_, { payload, hostId, houseId }) => {
      queryClient.setQueryData<TReview[]>(
        getReviewsQueryKey({ hostId }),
        (oldData) => {
          if (oldData) {
            const { created_at, rating, review_text } = payload;
            return oldData.map((r) => {
              if (
                r.created_at === created_at &&
                r.house_id === houseId &&
                r.host_id === hostId
              ) {
                return {
                  ...r,
                  rating: rating ?? r.rating,
                  review_text: review_text ?? r.review_text
                };
              } else return r;
            });
          }
        }
      );
    }
  });
};

// Searches
export const getSearchesQueryKey = ({ studentId }: { studentId?: string }) => [
  "searches",
  studentId
];

export const useGetSearches = (studentId?: string, enabled: boolean = true) => {
  return useQuery(
    getSearchesQueryKey({ studentId }),
    () => {
      if (!studentId) return;
      return StudentAPI.getSearches(studentId).then((result) =>
        Array.isArray(result) && result.length > 1
          ? result.sort((a, b) => sortByCreationTime(a, b, true))
          : result
      );
    },
    { enabled: Boolean(studentId) && enabled }
  );
};

export const useSaveSearchPreference = () => {
  const queryClient = useQueryClient();
  const { currentUserId } = useContextHospi();

  return useMutation<ISavedSearchPreference, unknown, ISearchPreferencePayload>(
    {
      mutationFn: (payload) =>
        StudentAPI.saveSearchPreference(payload, currentUserId),
      onSuccess: (data) => {
        queryClient.setQueryData<ISavedSearchPreference[]>(
          getSearchesQueryKey({ studentId: currentUserId }),
          (oldData) => {
            if (oldData) return [...oldData, data];
          }
        );
      }
    }
  );
};

export const useUpdateSavedSearch = () => {
  const queryClient = useQueryClient();
  const { currentUserId } = useContextHospi();

  return useMutation<
    ISavedSearchPreference,
    unknown,
    {
      payload: Partial<ISearchPreferencePayload>;
      createdAt: string;
    }
  >({
    mutationFn: ({ payload, createdAt }) =>
      StudentAPI.updateSearchPreference(createdAt, payload, currentUserId),
    onSuccess: (data, { createdAt }) => {
      queryClient.setQueryData<ISavedSearchPreference[]>(
        getSearchesQueryKey({ studentId: currentUserId }),
        (oldData) => {
          if (oldData)
            return oldData.map((s) =>
              s.created_at === createdAt ? { ...data } : s
            );
        }
      );
    }
  });
};

export const useDeleteSearchPreference = () => {
  const queryClient = useQueryClient();
  const { currentUserId } = useContextHospi();
  const { chosenSearch, chooseSearch } = useContextStudentSearches();

  return useMutation<unknown, unknown, string>({
    mutationFn: (createdAt) =>
      StudentAPI.deleteSearchPreference(createdAt, currentUserId),
    onSuccess: (_, createdAt) => {
      if (createdAt === chosenSearch?.created_at) chooseSearch(null);

      queryClient.setQueryData<ISavedSearchPreference[]>(
        getSearchesQueryKey({ studentId: currentUserId }),
        (oldData) => {
          if (oldData) return oldData.filter((s) => s.created_at !== createdAt);
        }
      );
    }
  });
};
