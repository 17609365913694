import { FC, InputHTMLAttributes } from "react";

import {
  Sign,
  BoxContainer,
  HidenInput,
  StyledContent,
  StyledBox
} from "./styles";

export interface ICheckbox extends InputHTMLAttributes<HTMLInputElement> {
  readonly h?: string;
  readonly p?: string;
  changeStatus?: (x: boolean) => void;
}

export const Checkbox: FC<ICheckbox> = ({ children, h, p, ...props }) => {
  return (
    <BoxContainer h={h} p={p}>
      <HidenInput {...props} type="checkbox" />
      <StyledBox checked={props.checked} type="checkbox">
        <Sign viewBox="0 0 24 24">
          <polyline points="20 6 9 17 4 12" />
        </Sign>
      </StyledBox>
      <StyledContent>{children}</StyledContent>
    </BoxContainer>
  );
};
