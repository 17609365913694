import { ReactNode } from "react";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";
import {
  HOUSE_STATUS,
  MatchStatusEnum,
  PRIVATE_USE,
  SHARED_USE,
  TYPE_ROOM,
  HOUSE_DEPUBLISH_REASONS,
  ISO_LANGUAGES
} from "utils/constants";

export interface IPostMetadata {
  post_id: string;

  published: boolean;
  tags: string[];
  authors: string[];
  title: {
    gb: string;
    nl: string;
  };
  slug: {
    gb: string;
    nl: string;
  };
  created_at: string;
  published_at: string | undefined;
  edited_at: string;

  cover_image: string;
  cover_image_caption: {
    gb: string;
    nl: string;
  };
  excerpt: {
    gb: string;
    nl: string;
  };

  keywords: string[];
}

export interface IPost extends IPostMetadata {
  body: {
    gb: string;
    nl: string;
  };
}

export interface IPostRedirect {
  redirect: boolean;
  post_id: string;
  slug: string;
}
export const SOCIAL_AUTH_PROVIDERS = [
  CognitoHostedUIIdentityProvider.Google
] as const;

export type TCognitoUserIdentities = {
  userId?: string;
  providerName?: (typeof SOCIAL_AUTH_PROVIDERS)[number];
  providerType?: string;
  issuer?: null;
  primary?: boolean;
  dateCreated?: number;
}[];

export type TAccountType = "premium" | "free";

export interface ICognitoAttributes {
  email: string;
  email_verified: boolean;
  sub: string; // user id
  locale?: string;
  profile?: RoleUser;
  identities?: JsonSerialized<TCognitoUserIdentities>; // defined for users signed in using Social Auth flow
  "custom:account_type"?: TAccountType;
}

export interface ICognitoUser {
  id: string;
  email: string;
  email_verified: boolean;
  isSocialAuthUser: boolean;
  locale?: string;
  role?: RoleUser;
  account_type?: TAccountType;
}

export type NonNullableProperties<T> = {
  [K in keyof T]: Exclude<T[K], null>;
};

export type RoleUser = "student" | "host";

export type TCustomOAuthState = RoleUser | "login";

export type THouseType = (typeof TYPE_ROOM)[number];
export const GenderValues = [
  "male",
  "female",
  "other",
  "prefer_not_answer"
] as const;
export const GenderValuesBE = ["male", "female", "any"] as const;

export type TGender = (typeof GenderValues)[number];
export type TGenderBE = (typeof GenderValuesBE)[number];
export type TVerify = "verifying" | "verified" | "unverified" | "failed";
export type TVerifyHostStatus = TVerify | "experienced" | "super";

export interface ISeo {
  title?: string;
  description?: string;
  keywords?: string;
  canonicallink?: string;
  nllink?: string;
  gblink?: string;
}

export interface IHousePhoto {
  file_name: string;
  url: string;
  timestamp: string;
  order: number;
}

export interface IEmail {
  type: "contact_form" | "newsletter" | "contact_me" | "partner";
  first_name?: string;
  last_name?: string;
  question?: string;
  phone?: string;
  category?: string;
  browser_language: string;
}

export interface IResponseShort {
  message: string;
  success: boolean;
}

export interface IUnsubscribeEmailsResponse {
  message: string;
  topic: string;
}

export interface IUploadAvatarResponse extends IResponseShort {
  thumbnail_url?: string;
}

export interface ICityOfResidence {
  country: string;
  province: string;
  city: string;
}

export type TContractStatus = "draft" | "host_signed" | "finished";
export type TPaymentStatus = "PAID" | "OPEN";
export enum PaymentStatusStripe {
  CREATED = "created",
  PENDING = "pending",
  COMPLETED = "completed",
  FAILED = "failed"
}

export interface IBaseMatchData {
  host_id: string;
  house_id: string;
  student_id: string;
}

export const CANCEL_MATCH_REASONS = [
  "personal_circumstances",
  "room_is_no_longer_available",
  "tenant_found_other_housing",
  "tenant_cancelled_with_other_reason",
  "other"
] as const;
export type TCancelMatchReason = (typeof CANCEL_MATCH_REASONS)[number];
export interface ICancelMatchData extends IBaseMatchData {
  reason?: TCancelMatchReason;
}

export interface IMatch extends IBaseMatchData {
  contract_id: string | null;
  contract_status: TContractStatus | null;
  // For student:
  payment_status: TPaymentStatus | null;
  payment_link: string | null;

  created_at?: string; // a match created_at string may end with "Z", e.g. "2024-12-15T11:51:32.905318Z"
  match_status?: MatchStatusEnum;
  student_check_in_date?: string;
  student_check_out_date?: string;
  offered_check_in_date?: string;
  offered_check_out_date?: string;
  check_in_date?: string;
  check_out_date?: string;
  checkout_date_actor?: RoleUser | null;
  score?: string | null;
}

export type TMatchData = {
  host_to_house: string;
  student_to_house: string;
} & Pick<
  IMatch,
  | "host_id"
  | "house_id"
  | "student_id"
  | "match_status"
  | "offered_check_in_date"
  | "offered_check_out_date"
  | "check_in_date"
  | "check_out_date"
  | "contract_id"
  | "contract_status"
  | "payment_status"
  | "payment_link"
  | "checkout_date_actor"
  | "created_at"
  | "score"
>;

export interface IUpdateCheckoutDatePayload extends IBaseMatchData {
  new_check_out_date: string;
}

type Enumerate<
  N extends number,
  Acc extends number[] = []
> = Acc["length"] extends N
  ? Acc[number]
  : Enumerate<N, [...Acc, Acc["length"]]>;
type TIntRange<F extends number, T extends number> =
  | Exclude<Enumerate<T>, Enumerate<F>>
  | T;

export type TRating = TIntRange<1, 5>;
export type TBaseReview = {
  rating: TRating;
  review_text: string;
};
export type TReview = TBaseReview & {
  host_id: string;
  approved: boolean;
  house_id: string;
  student_id: string;
  created_at: string; // e.g. "2024-09-19T14:34:06.500112"
  updated_at: string;
};
export type TReviewWithAuthorData = TReview & {
  authorTenant?: TUserShort;
};

export type TPersonValidationErrors = Partial<{
  [K in keyof IPerson]: string;
}>;

export interface IPerson {
  user_id: string;
  first_name?: string;
  last_name?: string;
  phone_code?: string;
  phone_number?: string;
  gender?: TGender;
  spoken_languages?: string[];
  default_language?: string;
  description?: string;
  privacy: number;
  user_type: RoleUser;
  not_provided_fields?: string[];
  // Student
  birth_date?: string | null;
  birth_country?: string | null;
  study?: string;
  past_experience?: TStudentPastExperience[];
  autonomy?: number;
  categorization?: TStudentCategorization[];
  propose?: TStudentPurpose[];
  propose_to_live_with_host?: TStudentPurposeToLiveWithHost[];
  ideal_match?: TStudentIdealMatch[];
  match_characteristics?: TStudentMatchCharacteristics[];
  amount_looking_people?: TStudentAmountLookingPeople;
  reason_to_become_tenant?: TStudentReasonToBecomeTenant[];
  other_search_options?: TOtherSearch[];

  source_to_hospi?: THostSourceToHospi[] | TStudentSourceToHospi[]; // has the same name for both host and student
  // Host
  reason_to_become_host?: THostReasonToBecomeHost[];
  experience_with_hosting?: THostExperienceWithHosting | null;

  // search preferences, student
  latitude?: number;
  longitude?: number;
  radius_in_meters?: number;
  search_location_string?: string;
  city_of_residence?: ICityOfResidence;
  max_budget?: number | null;
  min_area?: number | null;
  preferred_move_in?: string | null;
  preferred_move_out?: string | null;
  account_type?: TAccountType | null;
  premium_start_ts?: string | null; // string example "2025-02-12T13:02:18.895821"

  // Host
  requested_autonomy?: number;
  verification_status?: TVerify;
  hosted_tenants_count?: number;
  // Service
  is_profile_complete?: boolean;
  thumbnail_url?: string;
  //email preferences
  email_preferences_room?: boolean;
  email_preferences_chat?: boolean;
  email_preferences_general?: boolean;

  intake_data?: { start_time: string; end_time: string } | null;
}

export type TUserShort = Pick<
  IPerson,
  "first_name" | "last_name" | "thumbnail_url" | "user_id" | "account_type"
>;

export const StudentCategorizationValues = [
  "clean",
  "quiet",
  "eco_friendly",
  "animal_friendly",
  "social",
  "pro_active",
  "honest",
  "outgoing",
  "smoker",
  "vegetarian",
  "vegan"
] as const;
export type TStudentCategorization =
  (typeof StudentCategorizationValues)[number];

export const StudentPurposeValues = [
  "study",
  "work",
  "internship",
  "full_bachelor",
  "full_master",
  "bachelor_master_exchange",
  "PhD",
  "other"
] as const;
export type TStudentPurpose = (typeof StudentPurposeValues)[number];

export const StudentPurposeToLiveWithHostValues = [
  "affordable",
  "soft_landing",
  "learn_from_another_culture",
  "social_contact",
  "prefer_over_student_house",
  "relax",
  "safe",
  "clean",
  "just_looking_for_room",
  "other"
] as const;
export type TStudentPurposeToLiveWithHost =
  (typeof StudentPurposeToLiveWithHostValues)[number];

export const StudentIdealMatchValues = [
  "make_friends",
  "do_things_together",
  "have_chat",
  "own_things"
] as const;
export type TStudentIdealMatch = (typeof StudentIdealMatchValues)[number];
export const StudentMatchCharacteristicsValues = [
  "quiet",
  "city_center",
  "close_public_transport",
  "unique",
  "cosy",
  "spacious",
  "characteristic",
  "lots_greenery",
  "animal_friendly"
] as const;

export type TStudentMatchCharacteristics =
  (typeof StudentMatchCharacteristicsValues)[number];
export const StudentPastExperienceValues = [
  "Lived with host",
  "Lived abroad",
  "Lived on my own",
  "Lived with guest family",
  "None of the above"
] as const;
export type TStudentPastExperience =
  (typeof StudentPastExperienceValues)[number];

export const StudentReasonToBecomeTenantValues = [
  "amount_of_offers",
  "no_room_no_pay_model",
  "fully_verified_hosts",
  "personal_contact_and_service",
  "safety_guarantee_from_hospi",
  "help_soft_landing",
  "dont_care",
  "other"
] as const;
export type TStudentReasonToBecomeTenant =
  (typeof StudentReasonToBecomeTenantValues)[number];

export const StudentSourceToHospiValues = [
  "co_student_colleagues",
  "friends_family",
  "university_of_study",
  "university_will_study",
  "google",
  "social_media",
  "newspaper_or_news",
  "other"
] as const;
export type TStudentSourceToHospi = (typeof StudentSourceToHospiValues)[number];

export const OtherSearchValues = [
  "only_hospi",
  "university_housing",
  "facebook_groups",
  "other_housing_platforms",
  "other_channels"
] as const;
export type TOtherSearch = (typeof OtherSearchValues)[number];

export const StudentAmountLookingPeopleValues = [
  "myself",
  "two",
  "three",
  "four",
  "more_than_five"
] as const;
export type TStudentAmountLookingPeople =
  (typeof StudentAmountLookingPeopleValues)[number];

export const HostReasonToBecomeHostValues = [
  "space",
  "help",
  "income",
  "new_people",
  "learn_culture",
  "help_with_household",
  "other"
] as const;
export type THostReasonToBecomeHost =
  (typeof HostReasonToBecomeHostValues)[number];

export const HostSourceToHospiValues = [
  "google",
  "municipality_website",
  "newspaper_or_news",
  "flyer_or_poster",
  "friend",
  "other",
  "not_remember"
] as const;
export type THostSourceToHospi = (typeof HostSourceToHospiValues)[number];

export const HostExperienceWithHostingValues = [
  "yes",
  "little_bit",
  "no"
] as const;
export type THostExperienceWithHosting =
  (typeof HostExperienceWithHostingValues)[number];

export const HousePetsValues = ["no_pets", "dogs", "cats", "other"] as const;
export type THousePets = (typeof HousePetsValues)[number];

export const HousingTypeValues = [
  "own_property_w_mortgage",
  "own_property_no_mortgage",
  "rental_property_housing_corporation",
  "rental_property_private_landlord",
  "other"
] as const;
export type THousingType = (typeof HousingTypeValues)[number];

export const HouseCharacteristicsValues = [
  "single_person",
  "couple",
  "guest_family",
  "student_house",
  "community",
  "private_house"
] as const;
export type THouseCharacteristics = (typeof HouseCharacteristicsValues)[number];

export const HouseAccommodationValues = ["furnished", "non_furnished"] as const;
export type THouseAccommodation = (typeof HouseAccommodationValues)[number];

export type THousePrivateItems = (typeof PRIVATE_USE)[number];
export type THouseSharedItems = (typeof SHARED_USE)[number];

export const HouseAmountOfPeopleValues = [
  "one",
  "two",
  "three",
  "four",
  "more_than_five"
] as const;
export type THouseAmountOfPeople = (typeof HouseAmountOfPeopleValues)[number];
export const HouseSecurityItemsValues = [
  "smoke_detector",
  "fire_extinguisher",
  "carbon_monoxide_detector",
  "locked_rent_space",
  "covered_bicycle_shed"
] as const;
export type THouseSecurityItems = (typeof HouseSecurityItemsValues)[number];
export const HouseOtherItemsAndServicesValues = [
  "wifi",
  "tv",
  "washing_machine",
  "free_parking",
  "paid_parking",
  "bike_available",
  "shared_space_cleaning",
  "private_space_cleaning",
  "bedding_and_linen"
] as const;
export type THouseOtherItemsAndServices =
  (typeof HouseOtherItemsAndServicesValues)[number];

export const HouseCommonActivityOccurrenceValues = [
  "daily",
  "weekly",
  "monthly",
  "never"
] as const;
export type THouseCommonActivityOccurrence =
  (typeof HouseCommonActivityOccurrenceValues)[number];
export const HouseTenantDescriptionValues = [
  "vegetarian",
  "no_smoking",
  "flexitarian",
  "vegan"
] as const;
export type THouseTenantDescription =
  (typeof HouseTenantDescriptionValues)[number];

export const HouseTenantCharacteristicsValues = [
  "clean",
  "quiet",
  "eco_friendly",
  "animal_friendly",
  "social",
  "pro_active",
  "honest",
  "outgoing",
  "religious"
] as const;
export type THouseTenantCharacteristics =
  (typeof HouseTenantCharacteristicsValues)[number];

export interface IHouse {
  user_id?: string;
  house_id?: string;
  kind: THouseType | undefined;
  country: string;
  province?: string;
  postal_code: string;
  house_number: string;
  additional_house_number: string;
  street: string;
  city: string;
  bedroom_area: string | null;
  house_area: string | null;
  shared_items: THouseSharedItems[];
  private_items: THousePrivateItems[];
  description: string;
  host_description?: string | null;
  available_from: string;
  available_from_with_extra_month?: string;
  available_to: string | null;
  price: string | null;
  additional_expenses: string | null;
  service_costs: string | null;
  deposit: string | null;
  min_tennant_age: number;
  max_tennant_age: number;
  gender: TGenderBE | null;
  spoken_languages: string[];
  latitude?: number | string;
  longitude?: number | string;
  is_published?: boolean;
  photos?: IHousePhoto[];
  verification_status?: boolean;
  is_favorited?: boolean;
  published_at?: string | null;
  house_status?: (typeof HOUSE_STATUS)[number];
  security_items?: THouseSecurityItems[] | null;
  other_items_and_services?: THouseOtherItemsAndServices[] | null;
  household_description?: string | null;
  spend_time?: THouseCommonActivityOccurrence | null;
  help_tenant?: THouseCommonActivityOccurrence | null;
  amount_of_people?: THouseAmountOfPeople | null;
  accommodation?: THouseAccommodation | null;
  uniqueness_accommodation?: TStudentMatchCharacteristics[] | null;
  tenant_spoken_languages: string[];
  tenant_characteristics?: THouseTenantCharacteristics[] | null;
  tenant_description?: THouseTenantDescription[] | null;
  housing_type?: THousingType | null;
  housing_corporation_name?: string | null;
  characteristics?: THouseCharacteristics | null;
  people_living?: string[] | null;
  pets?: THousePets[] | null;
  created_at?: string; // string example: "2024-11-14T13:28:48.434989"
}

export type TRentPeriod = {
  rent_dates: { check_in_date: string; check_out_date: string }[];
};

export type THostShort = Pick<
  IPerson,
  "thumbnail_url" | "first_name" | "last_name" | "user_id"
>;

export type THouseModals = "delete" | null | "publish" | "save" | "depublish";

export interface IStudentStay {
  host: THostShort;
  houseId: string;
  period: {
    start?: string | null;
    end?: string | null;
    endedBy: RoleUser | null;
  };
  match_status: MatchStatusEnum;
  contract: {
    contract_id: string | null;
    contract_status: TContractStatus | null;
  };
  payment: {
    payment_status: TPaymentStatus | null;
    payment_link: string | null;
  };
  review?: TReview;
}

export type IUserShort = Pick<
  IPerson,
  | "user_id"
  | "user_type"
  | "thumbnail_url"
  | "first_name"
  | "last_name"
  | "gender"
  | "birth_date"
  | "birth_country"
  | "spoken_languages"
  | "description"
  | "autonomy"
  | "requested_autonomy"
  | "privacy"
  | "preferred_move_in"
  | "preferred_move_out"
  | "categorization"
  | "ideal_match"
  | "match_characteristics"
  | "search_location_string"
  | "propose"
  | "past_experience"
> & {
  active_years: number;
  house_rents_count?: number;
  hosted_tenants_count?: number;
  verification_status?: TVerify;
};

export type IHouseShortInfo = Pick<
  IHouse,
  | "available_from"
  | "available_to"
  | "house_id"
  | "max_tennant_age"
  | "min_tennant_age"
  | "gender"
  | "spoken_languages"
  | "published_at"
  | "street"
  | "house_number"
  | "additional_house_number"
  | "house_status"
> &
  TRentPeriod;

export type IHouseWithRentPeriod = IHouse & TRentPeriod;

export type IHouseRepublishPayload = Pick<
  IHouse,
  "available_from" | "available_to"
>;

export type THouseDepublishReason = (typeof HOUSE_DEPUBLISH_REASONS)[number];
export type THouseTogglePublishPayload = {
  un_publish_reasons: THouseDepublishReason[];
};

export type TTenantsHouse = Pick<
  IHouse,
  "house_id" | "price" | "additional_expenses" | "service_costs" | "deposit"
>;
export type TTenantPrices = Omit<TTenantsHouse, "house_id">;
export interface ITenants {
  match: Pick<
    TMatchData,
    | "check_in_date"
    | "check_out_date"
    | "match_status"
    | "contract_id"
    | "contract_status"
    | "checkout_date_actor"
  >;
  student: IUserShort;
  house: TTenantsHouse;
  host_id: string;
}

export interface IInputData {
  readonly title?: string;
  readonly placeholder?: string;
  readonly prefix?: string | ReactNode | undefined;
  children?: ReactNode;
}

export type StrNum = string | number;
export type DateNone = Date | undefined;

export interface ISelectItem {
  value: StrNum;
  label: string;
}

export interface IValidatorIn {
  readonly line: StrNum;
  params?: { [propName: string]: StrNum };
}

export interface IValidateOut {
  //   state: validatorState;
  state: boolean;
  message: string;
}

export const SearchPreferenceNotificationValues = ["daily", "instant"] as const;
export type TSearchPreferenceNotification =
  (typeof SearchPreferenceNotificationValues)[number];

export interface ISearchPreference {
  latitude?: number | string; // BE returns string
  longitude?: number | string; // BE returns string
  radius_in_meters: number;
  search_location_string?: string;
  chosen_country_of_residence?: string;
  chosen_province_of_residence?: string;
  max_budget?: number | null | string; // BE returns string | null
  min_area?: number | null | string; // BE returns string | null
  preferred_move_in?: string | null;
  preferred_move_out?: string | null;
  amount_looking_people?: TStudentAmountLookingPeople | null;
}
export interface ISearchPreferencePayload extends ISearchPreference {
  notification_type: TSearchPreferenceNotification;
  whatsapp_enabled?: boolean;
  email_enabled?: boolean;
  name?: string;
}
export interface ISavedSearchPreference extends ISearchPreferencePayload {
  user_id: string;
  created_at: string; // DateTime
  updated_at: string; // DateTime
}

export type TIsoLanguages = (typeof ISO_LANGUAGES)[number];
